.redwoodSelect {
    display: block;
    position: relative;
    border: 1px solid var(--blockquote-color, rgba(22,21,19,0.3));
    border-radius: 4px;
    height: 56px;
    margin: 30px auto 0;
    background-color: var(--pre-bg-color, #fff);
}

.redwoodSelect > div {
    /* margin-top: 6px; */
    margin-left: 8px;
}

.redwoodSelect div {
    background-color: var(--pre-bg-color, #fff);
    color: var(--main-form-color, #161513);
}

.redwoodSelect div[class$="placeholder"] {
    color: var(--blockquote-color, rgba(22, 21, 19, 0.6));
}
.redwoodSelect div[class$="control"] {
    border:none;
    box-shadow:none;
}

.redwoodSelect div[class$="singleValue"] {
    top: 65%;
    color: var(--blockquote-color, rgba(22, 21, 19, 0.6));
}

.redwoodSelect .inputContainer {
    margin-top:17px;
}

.redwoodSelect .floatedLabel {
    position:absolute;
    font-size: 12px;
    top: 8px;
    left: 10px;
    color: var(--blockquote-color, rgba(22, 21, 19, 0.6));
}

.redwoodSelect label, .redwoodSelect span {
    position: absolute;
    left: 16px;
    top: 8px;
    cursor: text;
    font-size: 75%;
    opacity: 1;
    transition: all .2s;
    color: var(--blockquote-color, rgba(22, 21, 19, 0.6));
}

.redBorder {
    border: 1px solid var(--main-bg-danger,#CA4936);
}

.redwoodSelect input:not(:focus) span, .redwoodSelect select:placeholder-shown:not(:focus) span {
    font-size: 25px;
    top: 15px;
}

.redwoodSelect input::-moz-placeholder, .redwoodSelect select::-moz-placeholder {
    opacity: 1;
    -moz-transition: all .2s;
    transition: all .2s;
}

.redwoodSelect input:-ms-input-placeholder, .redwoodSelect select:-ms-input-placeholder,
.redwoodSelect input::-ms-input-placeholder, .redwoodSelect select::-ms-input-placeholder {
    opacity: 1;
    -ms-transition: all .2s;
    transition: all .2s;
}
.redwoodSelect input::-webkit-input-placeholder, .redwoodSelect select::-webkit-input-placeholder,
.redwoodSelect input::placeholder, .redwoodSelect select::placeholder {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
    color: var(--blockquote-color, rgba(22, 21, 19, 0.6));
}
.redwoodSelect input:placeholder-shown:not(:focus)::-webkit-input-placeholder, .redwoodSelect select:placeholder-shown:not(:focus)::-webkit-input-placeholder {
    opacity: 0;
}
.redwoodSelect input:placeholder-shown:not(:focus)::-moz-placeholder, .redwoodSelect select:placeholder-shown:not(:focus)::-moz-placeholder {
    opacity: 0;
}
.redwoodSelect input:placeholder-shown:not(:focus):-ms-input-placeholder, .redwoodSelect select:placeholder-shown:not(:focus):-ms-input-placeholder {
    opacity: 0;
}
.redwoodSelect input:placeholder-shown:not(:focus)::-ms-input-placeholder, .redwoodSelect select:placeholder-shown:not(:focus)::-ms-input-placeholder {
    opacity: 0;
}
.redwoodSelect input:placeholder-shown:not(:focus)::placeholder, .redwoodSelect select:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
}
.redwoodSelect input:placeholder-shown:not(:focus) + *, .redwoodSelect select:placeholder-shown:not(:focus) + * {
    font-size: 16px;
    top: 15px;
}

.redwoodSelect label, .redwoodSelect span {
    position: absolute;
    left: 16px;
    top: 8px;
    cursor: text;
    font-size: 75%;
    opacity: 1;
    transition: all .2s;
    color: var(--blockquote-color, rgba(22, 21, 19, 0.6));
}