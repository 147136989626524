.optional {
    color: var(--main-form-color, rgba(22, 21, 19, 0.6));
    font-size: 12px;
    line-height: 16px;
    float:right;
    margin-top: 5px;
    margin-bottom: -5px;
}


