@import "../../../App.css";

.contentContainer {
  border-radius: 8px 8px 0 0;
  background: var(--pre-bg-color, #FFF);
  min-height: 500px;
  position:relative;
  box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  -webkit-box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
  -moz-box-shadow: 0 1px 16px 0 rgba(0,0,0,0.18);
}

.upperContentContainer {
  margin: 25px 0 0;
  padding: 32px 36px 0;
}

.contentContainer h1 {
  font-size: 28px;
  font-weight: 800;
  margin-top: 0;
}

.contentContainer .row {
  margin-right: -1rem;
  margin-left: -1rem;
}

.contentContainer::before{
  background:var(--pre-bg-color, url("../../../images/Color-Strip1.png"));
  height:8px;
  width: 100%;
  content: '';
  position:absolute;
  top:0;
  left:0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.termsComponent {
  padding: 32px 36px 32px;
}

.wrapper {
  min-height: 500px;
}

@media only screen and (max-width: 480px) {
  .contentContainer, 
  .progressContainer {
    min-width:295px;
  }
  .contentContainer::before{
    background:none;
    height:0;
  }
  .upperContentContainer {
    padding: 32px 24px 0;
    margin-top: 10px;
  }

  .col-xs-12.mainContainer  {
    padding-left:8px;
    padding-right:8px;
  }
}
